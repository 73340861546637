import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/2D/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/2D/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/2D/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/2D/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2023/3B/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2023/3B/6.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly2D2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
  },
  {
    src: p2,
    source:p2,
    rows: 1,
    cols: 1,
  },
  {
    src: p1,
    source: p1,
    rows: 1,
    cols: 2,
  },
  {
      src: p4,
      source: p4,
      rows: 1,
      cols: 2,
  },
  
      
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Republic Day 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/>
        Class: 2D   Date: 24 & 25 JANUARY 2024      
        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
         “A Nation’s culture resides in the heart and in the soul of its people.”
        <br/>
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 2D presented their assembly on the topic ‘Republic Day’ on Wednesday, 24 January and Thursday, 25 January 2024 to mark the celebration of Republic Day of India.    
        <br></br>
        The topic was introduced through a quote related to Republic Day, followed by a brief introduction on the topic and a melodious rendition of Vande Mataram played on the keyboard by a student. 
        On day 2, the assembly was presented in Hindi, beginning with a thought followed by an enchanting dance performance by the students emphasizing patriotism.
        <br></br>
        Overall, the assembly made the students understand the spirit of Republic Day and continued to cherish the democratic values and duties.
          <div>
          </div>
        </Typography>
        </Box>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        "Great nation is a nation that respects the services of the hero."
        <br/>
        </Typography>
        <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly2D2023;